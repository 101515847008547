export const environment = {
  baseUrl: "https://user.denariusfinance.es",
  production: true,
  environmentUrl: "https://denarius.es",
  userUrl: "https://user.denariusfinance.es",
  coreUrl: "https://core.denariusfinance.es",
  companyUrl: "https://company.denariusfinance.es",
  contactUrl: "https://contact.denariusfinance.es",
  shareHolderUrl:"https://minutesuploader.denariusfinance.es",
  dmsUrl: "https://dms.denariusfinance.es"
};
