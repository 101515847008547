import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, PublicGuard } from './core/services';
import { UnderConstructionComponent } from './shared/components/under-construction/under-construction.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { LegalNoticeComponent } from './shared/components/legal-notice/legal-notice.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './shared/components/cookies-policy/cookies-policy.component';


export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./modules/landing/landing.module").then(m => m.LandingModule),
    data:{
      title: 'Home',
      description: 'Denarius',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'junta-de-accionistas',
    loadChildren: () => import("./modules/shareholders/shareholders.module").then(m => m.ShareholdersModule),
    canActivate: [],
    data:{
      title: 'Login Accionistas',
      description: 'Denarius',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'empresas',
    loadChildren: () => import("./modules/business/business.module").then(m => m.BusinessModule),
    canActivate: [AuthGuard],
    data:{
      roles: ['BUSINESS'],
      title: 'Empresas',
      description: 'Área empresas de Denarius Finance',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'particulares',
    loadChildren: () => import("./modules/individual/individual.module").then(m => m.IndividualModule),
    canActivate: [AuthGuard],
    data:{
      roles: ['INDIVIDUAL'],
      title: 'Particulares',
      description: 'Área de particulares de Denarius Finance',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'onboarding',
    loadChildren: () => import("./modules/onboarding/onboarding.module").then(m => m.OnboardingModule),
    canActivate: [PublicGuard],
    data:{
      title: 'Área privada',
      description: 'Onboarding',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'error404', component: ErrorPageComponent
  },
  {
    path: 'pagina-en-construccion', component: UnderConstructionComponent
  },
  {
    path: 'faqs', component: FaqComponent
  },
  {
    path: 'aviso-legal', component: LegalNoticeComponent,
    data: {
      title: 'Aviso Legal ',
      description: 'Aviso Legal',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'politica-de-privacidad', component: PrivacyPolicyComponent, 
    data: {
      title: 'Política de Privacidad ',
      description: 'Política de Privacidad',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'politica-de-cookies', component: CookiesPolicyComponent,
    data: {
      title: 'Política de Cookies ',
      description: 'Política de Cookies',
      keywords: 'Hipoteca, Transferencias, Cuenta Joven, Préstamos'
    }
  },
  {
    path: 'card-contract-simulator', loadChildren: () => import("./modules/contracting/contract-card/card-contract-simulator/card-contract-simulator.module").then(m => m.SimulatorModule), canActivate: [AuthGuard]
  },
  {
    path: '**', redirectTo:'', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
