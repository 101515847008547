import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    if (title === undefined || title === '') {
      title = "Denarius";
    }
    this.title.setTitle(title + ' | Denarius');
  }
  

  updateOgUrl(url: string) {
    if (url === undefined) {
      url = "https://www.denariusfinance.es";
    }
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    if (desc === undefined) {
      desc = "Denarius";
    }
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeywords(keywords: string) {
    if (keywords === undefined) {
      keywords = "Denarius";
    }
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  addNoIndex() {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }



}
