import { PhoneValidationComponent } from '../../shared/components/phone-validation/phone-validation.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, finalize, map, Observable, throwError } from "rxjs";


@Injectable()
export class SCAInterceptor implements HttpInterceptor {

    constructor(public dialog: MatDialog) {
    }

    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const requestBody = request.body;

        return next.handle(request).pipe(
          catchError((err:HttpErrorResponse) => {
            if(err.error.sca && err.status === 428){
              /* this.openValidation() */
            }
            return throwError(() => err);

              
          }))
    }
        /*   this.dialog.open(PhoneValidationComponent, { data:{sca: sca, bodyData: requestBody, phone: '626603433'}})
 */
    openValidation(){
      this.dialog.open(PhoneValidationComponent, { data: { isClient: false }, disableClose: true })
    }
}
