import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string = "El Neobanco del sector salud | Denarius") {
    this.title.setTitle(`${title} | Denarius`);
    this.meta.updateTag({ name: 'og:title', content: `${title} | Denarius` });
    this.meta.updateTag({ name: 'twitter:title', content: `${title} | Denarius` });
  }

  updateOgUrl(url: string = "https://www.denarius.es") {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string = "Denarius - Tu solución financiera innovadora.") {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc })
  }

  updateKeywords(keywords: string = "Denarius, neobanco, sector salud, financiación, soluciones financieras") {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  updateOgImage(imageUrl: string = "https://www.denarius.es/assets/images/logo_denarius.png") {
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '630' });
  }

  addNoIndex() {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

}
