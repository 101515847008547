<div class="body-app">
    @if(!hideHeaderAndFooter) {
        <app-header-public  *ngIf="!isLoggedIn && !burgerMenuActive && !isShareholder" [linkListPublic]="linkListPublic" [isOnboarding]="isOnboarding"></app-header-public>
        <app-header-private *ngIf="isLoggedIn && !burgerMenuActive && role !== null && !isShareholder && !isShareholdersLogin" [linkListPrivate]="linkListPrivate"></app-header-private>
        <app-shareholders-header *ngIf="isShareholder && isShareholdersLogin"></app-shareholders-header>
        <app-side-nav *ngIf="burgerMenuActive && !isShareholder" class="sideNav_container" (openedEvent)="receiveOpenedSideNav($event)" [linkListPublic]="linkListPublic" [linkListPrivate]="linkListPrivate" [isLoggedIn]="isLoggedIn" [isOnboarding]="isOnboarding"></app-side-nav>
    }
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
    @if(!hideHeaderAndFooter) {
        <app-footer [isAuth]="_login" ></app-footer>

    }
</div>

