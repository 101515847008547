import { CookiesComponent } from './shared/components/cookies/cookies.component';
import { CookieService } from 'ngx-cookie-service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from './core/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderLinksModel, NavigationBarModel } from './core/models/navBar/navBar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SeoService } from './core/services/seo/seo.service';
import { filter, map, mergeMap } from "rxjs/operators";
import * as AOS from 'aos';
import { Segment } from './core/models/plans/plans';
import { AuthShareholdersService } from './core/services/auth-shareholders.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = $localize`:@@app.title:Denarius'`;
  public isLoggedIn: boolean = false;
  @Input() _login!: boolean;
  public isCookiesActive: boolean = false;
  public cookiesMsg!: string;
  public windowWidth!: any;
  public breakpoint = 991;
  public burgerMenuActive!: boolean;
  public isVisibleMenu!: boolean;
  public linkListPublic: HeaderLinksModel = { isLoggedIn: false, links:[]};
  public linkListPrivate: HeaderLinksModel = { isLoggedIn: false, links:[]};
  public product!: string;
  public role!: string;
  public isShareholder!: boolean;
  public isShareholdersLogin!: boolean;
  public isOnboarding!: boolean;
  public isProductionEnvironment: boolean = environment.production;

  constructor(
    private jwtHelper: JwtHelperService,
    private auth: AuthService,
    private cookies: CookieService,
    private snackBar: MatSnackBar,
    private router: Router,
    private seoService: SeoService,
    private _route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authShareholdersService: AuthShareholdersService,
    private breakpointObserver: BreakpointObserver,

  ) {

    this.linkListPublic = {
      isLoggedIn: false,
      links: [
        {
          title: "Inicio",
          url: "/",
        },
        {
          title: $localize`:@@nav.public.us:`,
          url: "/quienes-somos",
        },
        {
          title: "Productos",
          url: "/",
          queryParam: "products"
        },
        {
          title: $localize`:@@nav.public.contact:`,
          url: "/contacto",
        }
      ],
    },

      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
        .subscribe((event) => {
          this.seoService.updateTitle(event['title']);
          this.seoService.updateOgUrl(event['ogUrl']);
          this.seoService.updateDescription(event['description'])
          this.isShareholder = this.router.url.includes('/junta-de-accionistas')
          this.isOnboarding = this.router.url.includes('/onboarding');
          if (event['noIndex'] === true) {
            this.seoService.addNoIndex();
          }
        });
  }
  ngOnInit(): void {
    this.breakpointObserver
      .observe([`(max-width:${this.breakpoint}px)`])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.burgerMenu(true);
        } else {
          this.burgerMenu(false);
        }
      });

    AOS.init();
    window.addEventListener('load', AOS.refresh)

    if (!this.cookies.check('isConcent')) {
      this.isCookiesActive = true;
      this.snackBar.openFromComponent(CookiesComponent)
    } else {
      if (this.isProductionEnvironment) {
        this.isCookiesActive = false;
        this.loadGoogleAnalytics();
      } else if (!this.isProductionEnvironment) {
        this.isCookiesActive = false;
      }
    }
    this.authShareholdersService.setTokenSessionStorage();
    this.authShareholdersService.getTokenSessionStorage().subscribe(
      res => {
        this.isShareholdersLogin = res
      }
    )
    this.auth.setTokenSessionStorage();
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL ? this.role = 'particulares' :
      sessionStorage.getItem('role')! == Segment.BUSINESS ? this.role = 'empresas' : null;
    this.auth.getTokenSessionStorage().subscribe(
      res => {
        this._login = res;
        if (this._login) {
          this.isLoggedIn = true;
          this.product = '/retail'
          const sessionRole = sessionStorage.getItem('role')!
          sessionRole == Segment.INDIVIDUAL ? this.role = 'particulares' :
            sessionRole == Segment.BUSINESS ? this.role = 'empresas' : null;
          this.linkListPrivate = {
            isLoggedIn: true,
            links:
              [
                {
                  title: $localize`:@@nav.private.home:`,
                  url: `/${this.role}/area-privada`,
                  localize: "",
                },
                {
                  title: $localize`:@@nav.private.operational:`,
                  url: `/${this.role}/transferencias`,
                  localize: "",
                  deploy: true,
                  subtitles: [
                    {
                      title: $localize`:@@private.area.cards:`,
                      url: `/${this.role}/tarjetas`,
                      hideInProduction: true
                    },
                    {
                      title: $localize`:@@private.area.movements:`,
                      url: `/${this.role}/movimientos`,
                    },
                    {
                      title: $localize`:@@private.area.transfer:`,
                      url: `/${this.role}/transferencias`,
                    },
                    {
                      title: $localize`:@@private.area.receipts:`,
                      url: `/${this.role}/recibos`,
                    },
                    /* {
                      title: $localize`:@@private.area.debits:`,
                      url: `/${this.role}/domiciliaciones`,
                    }, */
                    {
                      title: $localize`:@@private.area.documentation:`,
                      url: `/${this.role}/consultar-documentacion`,
                    },
                    ...(sessionRole == Segment.BUSINESS ?
                      [{
                        title: $localize`:@@private.area.employees:`,
                        url: `/${this.role}/empleados`,
                        hideInProduction: true
                      }] : [])
                  ]
                },
                {
                  title: $localize`:@@nav.private.contracting:`,
                  url: this.product,
                  localize: "",
                  deploy: true,
                  subtitles: [
                    {
                      title: $localize`:@@private.area.accounts:`,
                      url: `/${this.role}/contratacion/cuentas`,
                    },
                    {
                      title: $localize`:@@private.area.cards:`,
                      url: `/${this.role}/contratacion/tarjetas`,
                      hideInProduction: true
                    },
                    {
                      title: $localize`:@@private.area.insurances:`,
                      url: `/${this.role}/contratacion/seguros`,
                      hideInProduction: true
                    },
                    {
                      title: $localize`:@@private.area.loan:`,
                      url: `/${this.role}/contratacion/prestamos`,
                      hideInProduction: true
                    },
                    {
                      title: 'Asesoramiento',
                      url: `/${this.role}/contratacion/asesoramiento`,
                    },
                    /*  {
                       title: 'Renting',
                       url: `/${this.role}/contratacion/renting`,
                     },  */
                    /*{
                        title: $localize`:@@private.area.mortgages:`,
                        url: "/pagina-en-construccion",
                      },*/
                  ]
                },
                {
                  title: 'Ecosistemas',
                  url: `/${this.role}/ecosistemas`,
                  localize: "",
                  deploy: true,
                  subtitles: [
                    {
                      title: 'Odontólogos',
                      url: `/${this.role}/ecosistemas/odontologos`,
                    },

                  ]
                },
              ]
          }

        

          //condición para añadir links al header private para el rol de empresa
          /* if (this.role === 'empresas') {
            this.linkListPrivate.forEach(section => {
              if (section.title === $localize`:@@nav.private.contracting:`) {
                section.subtitles?.splice(2, 0,
                  
                  {
                    title: 'Renting',
                    url: `/${this.role}/contratacion/renting`,
                  },
                )
              }
            })
          } */

        } else {
          this.isLoggedIn = false;

        }
        this.cdr.detectChanges();
      })


    const a = {
      title: this.title,
      description: $localize`:@@app.title:`
    }

  }

  loadGoogleAnalytics() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-3RXGLGXKES`;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-3RXGLGXKES');
    `;
    document.head.appendChild(script2);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }
  receiveToggleMenu(event: boolean) {
    this.isVisibleMenu = event
  }


  burgerMenu(burger: boolean) {
    this.burgerMenuActive = burger;
    const personalAreaIndex = this.linkListPrivate.links.findIndex(item => item.title === 'Área personal');

    // Si el menú está activo y el enlace no está en la lista, agregarlo
    if (this.burgerMenuActive && personalAreaIndex === -1) {
        this.linkListPrivate.links.push({
            title: 'Área personal',
            url: `/${this.role}/area-personal`
        });
    }
    // Si el menú no está activo y el enlace está en la lista, eliminarlo
    else if (!this.burgerMenuActive && personalAreaIndex !== -1) {
        this.linkListPrivate.links.splice(personalAreaIndex, 1);
    }
  }

  receiveOpenedSideNav(val: boolean) {
    if (val) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }
}

