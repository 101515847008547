import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { I18nModule } from './i18n/i18n.module';
import { JwtInterceptor, SCAInterceptor, ErrorInterceptor } from './interceptors';
import { AuthGuard, AuthService, LoginService, PublicGuard } from './services';
import { SharedModule } from '../shared/shared.module';
import { AuthShareholdersService } from './services/auth-shareholders.service';



@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return sessionStorage.getItem('token');
        }
      }
    })
  ],
  providers: [
    AuthService,
    AuthShareholdersService,
    LoginService,
    JwtHelperService,
    AuthGuard,
    PublicGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
 /*    { provide: HTTP_INTERCEPTORS, useClass: SCAInterceptor, multi: true }, */
    I18nModule.setLocale(), 
    I18nModule.setLocaleId()
  ]
})

export class CoreModule { }
