import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShareholdersModule } from './modules/shareholders/shareholders.module';
import { DropFilesDirective } from './core/directives/drop-files.directive';
import { CustomMatPaginatorIntl } from './core/services/customMatPaginatorIntl';
import { MatPaginatorIntl } from '@angular/material/paginator';


@NgModule({
  declarations: [
    AppComponent,
    DropFilesDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    ShareholdersModule,
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },],
})
export class AppModule { }
