import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';

@Injectable({
    providedIn: 'root',
})
class I18n {
    locale = 'es';

    async setLocale() {
        const userLocale = sessionStorage.getItem('locale');

        // Si el usuario tiene un idioma preferido almacenado en localStorage úsalo.
        if (userLocale) {
            this.locale = userLocale;
        }

        // Usa la webpack magic string para incluir solo los datos de configuración regional requeridos
        const localeModule = await import(
            /* webpackInclude: /(de|en|es)\.mjs$/ */
            `../../../../node_modules/@angular/common/locales/${this.locale}.mjs`
        );

        // Establecer configuración regional en pipes etc.
        registerLocaleData(localeModule.default);

        // Carga el archivo de traducción
        const localeTranslationsModule = await import(
            `../../../assets/i18n/${this.locale}.json`
        );

        // Carga las traducciones para la configuración regional actual en tiempo de ejecución
        loadTranslations(localeTranslationsModule.default);
    }
}

// Carga los datos de configuración regional al inicio de la aplicación
function setLocale() {
    return {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18n) => () => i18n.setLocale(),
        deps: [I18n],
        multi: true,
    };
}

// Establece la configuración regional de tiempo de ejecución para la aplicación
function setLocaleId() {
    return {
        provide: LOCALE_ID,
        useFactory: (i18n: I18n) => i18n.locale,
        deps: [I18n],
    };
}

export const I18nModule = {
    setLocale: setLocale,
    setLocaleId: setLocaleId,
};
