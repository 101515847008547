import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";
import { AuthShareholdersService } from "../services/auth-shareholders.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private authShareHolderService: AuthShareholdersService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isLoggedIn = this.authenticationService.isAuthenticated()
        const isShareHolderLoggedIn = this.authShareHolderService.isAuthenticated()
        const isApiUrl = (
            (request.url.startsWith(environment.userUrl) 
            || request.url.startsWith(environment.coreUrl) 
            || request.url.startsWith(environment.companyUrl)
            || request.url.startsWith(environment.contactUrl)
            || request.url.startsWith(environment.dmsUrl)
            || request.url.startsWith(environment.shareHolderUrl))
            && !request.url.includes("/onboarding")
            && !request.url.includes("/login")
        )

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${this.authenticationService.token()}`)
            });
            
        }
        if (isShareHolderLoggedIn && isApiUrl) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${this.authShareHolderService.token()}`)
            });
            
        }

        return next.handle(request);
    }
}