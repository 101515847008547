import { MaterialModule } from 'src/app/shared/material.module';
import { Component } from '@angular/core';
import {
  MatSnackBarAction,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  providers: [CookieService],
  standalone: true,
  imports: [MaterialModule, MatSnackBarModule],
})
export class CookiesComponent {
  _cookieValue: any;
  _isConcent: any = false;
  public isProductionEnvironment: boolean = environment.production;
  constructor(
    private cookieService: CookieService,
    private snackBarRef: MatSnackBarRef<MatSnackBarAction>,
    private router: Router
  ) {}

  setCookies() {
    if (this.isProductionEnvironment) {
      this._isConcent = true;
      this.cookieService.set('isConcent', 'true');
      this._cookieValue = this.cookieService.get('isConcent');
      this.loadGoogleAnalytics();
      this.snackBarRef.dismiss();
    } else if (!this.isProductionEnvironment){
      this._isConcent = true;
      this.cookieService.set('isConcent', 'true');
      this._cookieValue = this.cookieService.get('isConcent');
      this.snackBarRef.dismiss();
    }
    
  }

  declineCookies() {
    this.snackBarRef.dismiss();
  }

  loadGoogleAnalytics() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-3RXGLGXKES`;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-3RXGLGXKES');
    `;
    document.head.appendChild(script2);
  }

  goToCookies() {
    this.router.navigate(['politica-de-cookies'])
  }
}
