import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) {}

  updateSeo({
    title = "El Neobanco de salud | Denarius",
    description = "Denarius - Tu solución financiera innovadora.",
    url = environment.environmentUrl || 'https://www.denarius.es',
    keywords = "Denarius, neobanco, sector salud, financiación, soluciones financieras",
    image = "/assets/images/logo_denarius.png",
    imageWidth = '1200',
    imageHeight = '630',
    noIndex = false
  }) {
    const fullTitle = `${title} | Denarius`;
    const imageUrl = image.startsWith('http') ? image : `${url}${image}`;
    const robotsContent = noIndex ? 'noindex, nofollow' : 'index, follow';

    this.title.setTitle(fullTitle);

    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'robots', content: robotsContent });

    this.meta.updateTag({ property: 'og:title', content: fullTitle });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ property: 'og:image:width', content: imageWidth });
    this.meta.updateTag({ property: 'og:image:height', content: imageHeight });

    this.meta.updateTag({ name: 'twitter:title', content: fullTitle });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  }
}
